import ReactGA from "react-ga"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"

import theme from "@/assets/theme"

import "@/assets/global.css"

ReactGA.initialize("UA-177854191-1", {
  debug: false,
})

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <link
        rel="preload"
        href="/fonts/Graphik-Bold.woff2"
        type="font/woff2"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Graphik-SemiBold.woff2"
        type="font/woff2"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Graphik-Medium.woff2"
        type="font/woff2"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Graphik-Regular.woff2"
        type="font/woff2"
        as="font"
        crossOrigin="anonymous"
      />
    </Helmet>
    {element}
  </ThemeProvider>
)
