export const colors = {
  primary: "#FFC700",
  primaryHover: "#d4a600",
  primaryActive: "#8f7300",
  black: "#25272B",
}

const theme = {
  colors,
}

export default theme
